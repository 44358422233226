import * as React from "react"
// ______________________________________________________
//
const Component: React.FC = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)
// ______________________________________________________
//
export default Component
